<template>
  <div>

    <b-card no-body class="p-50">
      <b-card-header class="p-50" />
      <b-card-body>
        <b-row 
        class="mb-6"
        >
          <b-col
            cols="12"
            
            class="mb-6"
          >
            
              <h4
              class="mb-6"
              > بحث حسب   </h4>
             

         
          </b-col>
          <b-col
            cols="4"
            md="4"
            class="mb-md-0 mb-2"
          >
            <validation-provider
              name="search"
            >
              <h6> الاسم الأول</h6>
              <b-form-input
                v-model="firstName"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"

                class="w-100"
              />

            </validation-provider>
          </b-col>
          <b-col
            cols="4"
            md="4"
            class="mb-md-0 mb-2"
          >
            <validation-provider
              name="search"
            >
              <h6> الاسم الأخير</h6>
              <b-form-input
                v-model="lastName"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"

                class="w-100"
              />

            </validation-provider>
          </b-col>
          <b-col
            cols="4"
            md="4"
            class="mb-md-0 mb-2"
          >
            <validation-provider
              name="search"
            >
              <h6> رقم البطاقة </h6>
              <b-form-input
                v-model="cardNumber"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"

                class="w-100"
              />

            </validation-provider>
          </b-col>
        </b-row>
        <hr>
       
        <br>
        <b-row>
          <b-col
            cols="12"
            
            class="mb-6"
          >
            
              <h4
              class="m-6"
              >  تاريخ التسجيل   </h4>
             

         
          </b-col>
          <b-col
          cols="4"
            md="4"
            class="mb-md-0 mb-2"
          >
            <h6>من</h6>
            <flat-pickr
              v-model="startDate"
              class="form-control"
              :config="{ maxDate: maxDate }"
            />
          </b-col>
          <b-col
          cols="4"
            md="4"
            class="mb-md-0 mb-2"
          >
            <h6>إلى</h6>
            <flat-pickr
              v-model="endDate"
              class="form-control"
              :config="{ maxDate: maxDate }"
            />
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
    <!-- Table Families Card -->
    <b-card no-body class="mb-0">
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
       
      

          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Show</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>entries</label>
          </b-col>

        
        </b-row>
      </div>

      <b-table
        ref="refFamiliesListTable"
        :items="fetchFamilies"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        :current-page="currentPage"
        :per-page="perPage"
        stacked="md"
        :sort-desc.sync="isSortDirDesc"
        :filter="search"
        small
      >
      
        <template #cell(barecode)="data">
          <div class="text-nowrap">
            <span class="align-text-top text-capitalize">{{ data.item.barecode }}</span>
          </div>
        </template>
        <template #cell(visited)="data">
          <div class="text-nowrap" >
            <span class="align-text-top text-capitalize" v-if="data.item.visited==1">نعم</span>
            <span class="align-text-top text-capitalize" v-else>لا</span>
          </div>
        </template>
        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-dropdown variant="link" no-caret :right="$store.state.appConfig.isRTL">
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>

            <b-dropdown-item
              :to="{ name: 'families-Beneficiaries', params: { id: data.item.id } }"
            >
              <feather-icon icon="EyeIcon" />
              <span class="align-middle ml-50">عرض</span>
            </b-dropdown-item>
            <b-dropdown-item
              :to="{ name: 'edit-families-details', params: { id: data.item.id } }"
            >
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">تعديل</span>
            </b-dropdown-item>
              <b-dropdown-item-button
               @click="editBarcode( data.item.id)"
            >
              <feather-icon icon="CheckSquareIcon" />
                <span class="align-middle ml-50">تعديل رقم البطاقة</span>
            </b-dropdown-item-button>
           
          </b-dropdown>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted"
              >Showing {{ dataMeta.from }} to {{ dataMeta.to }} of
              {{ dataMeta.of }} entries</span
            >
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalFamilies"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardBody,
  BFormGroup,
  BInputGroup,
  BInputGroupAppend,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BDropdownItemButton,
  BPagination,
  BForm,
  BModal, VBModal,
} from "bootstrap-vue";
import vSelect from "vue-select";
import store from '@/store'
import { ref, onUnmounted } from "@vue/composition-api";
import { avatarText } from "@core/utils/filter";
import flatPickr from 'vue-flatpickr-component'
import 'flatpickr/dist/flatpickr.css'
import { max } from '@validations'

import useFamiliesList from "./useFamiliesList";
import familyStoreModule from "../familyStoreModule";
export default {
  setup() {
    const FAMILY_APP_STORE_MODULE_NAME = "app-family";

    // Register module
    if (!store.hasModule(FAMILY_APP_STORE_MODULE_NAME))
      store.registerModule(FAMILY_APP_STORE_MODULE_NAME, familyStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(FAMILY_APP_STORE_MODULE_NAME))
        store.unregisterModule(FAMILY_APP_STORE_MODULE_NAME);
    });
    const now = new Date()
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate())
    const maxDate = new Date(today)
    maxDate.setMonth(maxDate.getMonth())

    const {
      tableColumns,
      perPage,
      currentPage,
      totalFamilies,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refFamiliesListTable,
      refetchData,

      fetchFamilies,
      editBarcode,

      // Extra Filters
      firstName,
      lastName,
      cardNumber,
      startDate,
      endDate
    } = useFamiliesList();

    return {
      // Sidebar
      search: null,
      fetchFamilies,

      filterOn: [],
      searchTerm: "",
      tableColumns,
editBarcode,
      perPage,
      currentPage,
      totalFamilies,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refFamiliesListTable,
      refetchData,
maxDate,
      // Filter
      avatarText,
      firstName,
      lastName,
      cardNumber,
      startDate,
      endDate
    };
  },
  components: {
    BFormGroup,
    BInputGroup,
    BInputGroupAppend,
    BDropdownItemButton,
    BCard,
    BCardHeader,
  BCardBody,
    BModal, VBModal,
    BForm,
flatPickr,
max,

    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,

    vSelect,
  },
};
</script>

<style scoped>
div {
  color: #7e7a9f;
  font-weight: bold;
  font-family: "Cairo", sans-serif;
}
h4 {
  margin-bottom: '20px',
}
</style>
<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>